import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { GeneralHealthHistoryData } from 'app/shared/models';
import { UserDataApiActions } from '../actions/user-data-api.actions';
import { v4 } from 'uuid';
import { ModalsActions } from 'app/core/modals/actions/modals.actions';

export interface GeneralHealthHistoryState extends EntityState<GeneralHealthHistoryData> {}

export const generalHealthHistoryAdapter = createEntityAdapter<GeneralHealthHistoryData>({
  selectId: () => v4(),
});

const initialState = generalHealthHistoryAdapter.getInitialState();

export const generalHealthHistoryReducer = createReducer(
  initialState,
  on(UserDataApiActions.getUserGeneralHealthHistoryDataSuccess, (state, { payload }) =>
    generalHealthHistoryAdapter.upsertMany(payload, { ...state })
  ),
  on(UserDataApiActions.purgeUserData, (state) =>
    generalHealthHistoryAdapter.removeAll({ ...state })
  ),
  on(ModalsActions.closeUserDetailsModal, (state) =>
    generalHealthHistoryAdapter.removeAll({ ...state })
  ),
  on(UserDataApiActions.getUserGeneralHealthHistoryData, (state) =>
    generalHealthHistoryAdapter.removeAll({ ...state })
  )
);
