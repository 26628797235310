import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UserDataApiActions } from '../actions/user-data-api.actions';
import {
  SymptomHistoryQuestionGroupsData
} from 'app/shared/models/users/user-data/symptom-history-data.model';
import { ModalsActions } from 'app/core/modals/actions/modals.actions';

export interface SymptomsHistoryState extends EntityState<SymptomHistoryQuestionGroupsData> {
}

export const symptomHistoryAdapter = createEntityAdapter<SymptomHistoryQuestionGroupsData>({
  selectId: (symptomGroupsData) => symptomGroupsData.questionGroup,
});

const initialState = symptomHistoryAdapter.getInitialState();

export const symptomHistoryReducer = createReducer(
  initialState,
  on(UserDataApiActions.getUserSymptomsHistoryDataSuccess, (state, { payload, questionGroup }) => {
      return symptomHistoryAdapter.upsertOne({
        questionGroup: questionGroup!,
        symptomHistoryData: payload
      }, { ...state })
    }
  ),
  on(UserDataApiActions.purgeUserData, (state) => symptomHistoryAdapter.removeAll({ ...state })),
  on(ModalsActions.closeUserDetailsModal, (state) => symptomHistoryAdapter.removeAll({ ...state })),
  on(UserDataApiActions.getUserSymptomsHistoryData, (state) => symptomHistoryAdapter.removeAll({ ...state }))
);
